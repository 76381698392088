<template>
  <viewcard--c title="Empresas" :btnew="btnew">
    <!-- list items -->
    <view--c permission="permission.company.view" :busy="isloading">
      <Table
        :fields="fields"
        :list="!isloading && list[currentePage] ? list[currentePage].itens : []"
        @rowClick="onClickSelected"
        @orderBy="getRecordsOrderBy"
        border="full"
        responsive
      >
        <template #color="data">
          <b-badge
            pill
            style="min-width: 90px"
            :style="`background: ${data.item.color}`"
          >
            {{ data.item.color }}
          </b-badge>
        </template>
      </Table>

      <!-- paginação -->
      <b-pagination
        v-model="currentePage"
        @change="getLoadMore"
        :total-rows="rows"
        :per-page="size"
        v-if="rows > 1"
        first-number
        last-number
        align="center"
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </view--c>
  </viewcard--c>
</template>

<script>
import Table from "@/components/Table.vue";
import _companyService from "@/services/company-service";
export default {
  components: {
    Table,
  },
  data() {
    return {
      borderGrid: false,
      btnew: {
        permission: "permission.company.create",
        to: "/registrations/company/0",
      },
      isloading: false,
      currentePage: 1,
      size: this.$utils.paginationSize(),
      rows: 1,
      fields: [
        { key: "name", label: "Nome", orderBy: { type: "back" } },
        { key: "color", label: "Cor Padrão" },
        {
          key: "amountconcurrentevents",
          label: "Qtd. Eventos Simultâneos",
          orderBy: { type: "back" },
        },
      ],
      list: [
        {
          page: 0,
          itens: [],
        },
      ],
      orderByParams: {
        search: '',
        ascOrDes: true,
        orderByKey: ''
      },
    };
  },
  created() {
    this.getRecords(this.currentePage);
  },
  methods: {
    getRecords(_page, isOrder = false) {
      this.isloading = true;
      _companyService
        .show(_page, this.orderByParams)
        .then((res) => {
          if (res.content) {
            this.list.push({ page: _page, itens: res.content });
            if (res.content.length > 0) {
              if (isOrder) return;
              this.rows += res.content.length;
              this.currentePage = _page;
            }
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => {
          this.orderList();
          this.isloading = false;
        });
    },
    getLoadMore(_page) {
      if (!this.list.some((s) => s.page === _page)) {
        this.getRecords(_page);
      }
    },
    getRecordsOrderBy(_params) {
      this.orderByParams.ascOrDes = _params.ascOrDes;
      this.orderByParams.orderByKey = _params.orderByKey;
      let oldList = this.list.map((m) => m);
      this.list = [{ page: 0, itens: [] }];
      oldList.forEach((l) => {
        if (l.page < 1) return;
        this.getRecords(l.page, true);
      });
    },
    orderList() {
      this.list.sort((a, b) => {
        if (a.page < b.page) return -1;
        if (a.page > b.page) return 1;
        return 0;
      });
    },
    onClickSelected(record, _) {
      this.$router.push({
        path: `/registrations/company/${record.id}`,
      });
    },
  },
};
</script>